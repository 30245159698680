
import "./bootstrap";

import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';


import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

import { useNavigate } from "react-router-dom";
// import Logo from './assets/fire1310';
import { ReactComponent as Logo } from './assets/FF1310.svg';
import { useTheme } from '@mui/material/styles';


import { Firebase } from './components/firebase';
import Box from "@mui/material/Box";

const Login = (props) => {

  const themeSpacing = 8;

  const styles= {
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      maxWidth: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    paper: {
      marginTop: themeSpacing,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: themeSpacing*2,
    },
    submit: {
      marginBottom: 10,
    },
    avatar: {
      margin: themeSpacing,
      backgroundColor: 'grey',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: themeSpacing,
    },
    submit: {
      marginTop: themeSpacing,
    },
  };

  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const [token, setToken] = React.useState("");
  const [staff] = React.useState(props.staff);
  const [password, setPassword] = React.useState("");
  let navigate = useNavigate();


  React.useEffect(() => {
    if(window.location != null) {
      const params = new URLSearchParams(window.location.search);

      if(params.get('email')) setEmail(params.get('email'));
      if(params.get('token')) setToken(params.get('token'));
    }

  }, [])

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  }

  const handleSubmit = () => {

    var authUser = staff.find(element => element.email === email)


    Firebase.auth().signInWithEmailAndPassword(email, password)
      .then((user) => {
        // console.log("##### 1 # LOGIN")
        //
        // console.log(authUser)
        // console.log(token)
        // console.log(email)
        // console.log(token.length)
        // console.log("##### LOGIN")

        if(token.length > 0) {
          console.log("update token")

          Firebase.database().ref('staff/' + authUser.key).update({
            token: token ? token : ""
          }).then(() => {
            setError('Benutzerdaten korrekt ')
            navigate('/')
          })
        }
        else {
          setError('Benutzerdaten korrekt ')
          navigate('/')
        }

      })
      .catch((error) => {
        setError('Bitte überprüfe deine Zugangsdaten: ' + error.message)
      });
  }

  const register = () => {
    // console.log(email)
    var authUser = staff.find(element => element.email === email)

    // console.log(authUser)

    if(authUser) {
      Firebase.auth().createUserWithEmailAndPassword(email, password)
        .then((user) => setError('Benutzerdaten korrekt '))
        .catch((error) => setError('Fehler bei der Registrierung: ' + error.message));
    } else {
        setError('Registrierung der E-Mail ' + email + ' ist nicht zulässig.')
    }
  }

  const { classes } = props;

  return (
    <Box>
      <CssBaseline />
        <AppBar position="static" color="primary">
          <Toolbar>
            <Logo height="60px" style={{marginTop: 7.5}}/>
          </Toolbar>
        </AppBar>
        <Box style={styles.main}>
          <Paper style={styles.paper}>
            <Avatar style={styles.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Anmeldung
            </Typography>

            <FormControl margin="normal" required fullWidth
            value={email}
            onChange={event => setEmail(event.target.value.toLowerCase())}>
              <InputLabel htmlFor="email">E-Mail Adresse</InputLabel>
              <Input id="email" name="email" value={email} autoComplete="email" autoFocus />
            </FormControl>
            <FormControl margin="normal" required fullWidth
            value={password}
            onChange={event => setPassword(event.target.value)}>
              <InputLabel htmlFor="password">Passwort</InputLabel>
              <Input name="password" type="password" id="password" autoComplete="current-password" />
            </FormControl>
            {error}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!validateForm()}
              style={styles.submit}
              onClick={handleSubmit}
            >
              Anmelden
            </Button>

          </Paper>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="secondary"
            style={styles.submit}
            onClick={register}
          >
            Registrieren
          </Button>
        </Box>
      </Box>
    )
}

export default Login;

import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import { Firebase } from './components/firebase';

import useMediaQuery from '@mui/material/useMediaQuery';
import * as serviceWorker from './serviceWorker';

import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { BrowserRouter as BrowserRouter, Routes, Route } from "react-router-dom";

import { UserContext } from './shared/context';

import LoadingLogin from './LoadingLogin';
import Login from './Login';

import DateFnsUtils from '@date-io/date-fns';
import deLocale from "date-fns/locale/de";


const Dashboard = lazy(() =>  import('./pages/Dashboard'));
const NewsTable = lazy(() =>  import('./pages/NewsTable'));
const ShiftTable = lazy(() =>  import('./pages/ShiftTable'));
const TrainingTable = lazy(() =>  import('./pages/TrainingTable'));
const StaffTable = lazy(() =>  import('./pages/StaffTable'));
const SessionTable = lazy(() =>  import('./pages/SessionTable'));
const ArchiveTable = lazy(() =>  import('./pages/ArchiveTable'));
const Wachbesetzung = lazy(() =>  import('./pages/Wachbesetzung'));
const Wachdisplay = lazy(() =>  import('./pages/Wachdisplay'));
const TimeReceipts = lazy(() =>  import('./pages/TimeReceipts'));
const StaffListPDF = lazy(() =>  import('./pages/StaffListPDF'));
const Merchandise = lazy(() =>  import('./pages/Merchandise'));
const AlarmCounter = lazy(() =>  import('./pages/AlarmCounter'));
const AlarmApp = lazy(() =>  import('./pages/AlarmApp'));
const Stats = lazy(() =>  import('./pages/InternalStats'));

const App = ()  => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
      () =>
          createTheme(adaptV4Theme({
            palette: {
              mode: prefersDarkMode ? 'dark' : 'light',
              primary: {
                light: '#E74B58',
                main: '#D82333',
                dark: '#990815',
                contrastText: '#fff',
              },
              secondary: {
                light: '#E55864',
                main: '#939393',
                dark: '#990815',
                contrastText: '#000',
              },
            },
          })),
      [prefersDarkMode],
  );


  const [user, setUser] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [staff, setStaff] = React.useState(null);
  var authSubscription;

  const getStaffList = () => {

    const staffListRef = Firebase.database().ref('staff').orderByChild("position")
    var staffList = []

    staffListRef.equalTo("VF").once('value', (snapshot) => {
      pushDataToArray(snapshot,staffList)
      // console.log("ZF" + staffList)
    })

    staffListRef.equalTo("ZF").once('value', (snapshot) => {
      pushDataToArray(snapshot,staffList)
      // console.log("ZF" + staffList)
    })


    staffListRef.equalTo("STF").once('value', (snapshot) => {
      pushDataToArray(snapshot,staffList)
      // console.log("STF" + staffList)
    })


    staffListRef.equalTo("TF").once('value', (snapshot) => {
      pushDataToArray(snapshot,staffList)
      // console.log("TF" + staffList)
    })

    staffListRef.equalTo("TM").once('value', (snapshot) => {

      pushDataToArray(snapshot,staffList)
      setStaff(staffList ? staffList : {})
    })

  }

  const pushDataToArray = (snapshot, staffList) => {
    snapshot.forEach(child => {

      staffList.push({
        key: child.key,
        ...child.val()
      })
    });
  }

// Fetch the token from storage then navigate to our appropriate place
  const checkUser = () => {
    authSubscription = Firebase.auth().onIdTokenChanged((user) => {

      // Force user update to get latest activation state
      if(user) setUser(user)
      else setUser(null)

      setLoading(false)
    });

  };

  React.useEffect(() => {
    checkUser()
    getStaffList()
  }, [])


  const components = {
    "dashboard": Dashboard,
    "news": NewsTable,
    "shifts": ShiftTable,
    "trainings": TrainingTable,
    "sessions": SessionTable,
    "alarmcounter": AlarmCounter,
    "stafftable": StaffTable,
    "archive": ArchiveTable,
    "display": Wachdisplay,
    "timereceipts": TimeReceipts,
    "shop": Merchandise,
    "app": AlarmApp,
    "stats": Stats
  };

  const loadingOrLogin = (componentToelement) => {

    if(loading == false && user == null && staff !== null) return <Login staff={staff}/>
    if((user == null || staff == null)) return <LoadingLogin />

    var MyComponent = components[componentToelement];
    return <MyComponent staff={staff}/>;
  }

  return (
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Suspense fallback={ <LoadingLogin />}>
              <UserContext.Provider value={user}>
                <Routes>
                  <Route path="/" element={loadingOrLogin("dashboard")} />
                  <Route path="/neuigkeiten" element={loadingOrLogin("news")} />
                  <Route path="/einsatzdienste" element={loadingOrLogin("shifts")} />
                  <Route path="/termine" element={loadingOrLogin("trainings")} />
                  <Route path="/uebungsdienst" element={loadingOrLogin("sessions")} />
                  <Route path="/alarmzaehler" element={loadingOrLogin("alarmcounter")} />
                  <Route path="/personal" element={loadingOrLogin("stafftable")} />
                  <Route path="/archiv" element={loadingOrLogin("archive")} />
                  <Route path="/wachdisplay" element={loadingOrLogin("display")} />
                  <Route path="/stundenzettel" element={loadingOrLogin("timereceipts")} />
                  {/*<Route path="/personal-pdf" element={loadingOrLogin(<StaffListPDF staff={staff}/>)} />*/}
                  <Route path="/shop" element={loadingOrLogin("shop")} />
                  <Route path="/alarmapp" element={loadingOrLogin("app")} />
                  <Route path="/internalstats" element={loadingOrLogin("stats")} />
                </Routes>
              </UserContext.Provider>
            </Suspense>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
  );
}


const root = createRoot(document.getElementById('root'));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


import "./bootstrap";

import React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import CircularProgress from '@mui/material/CircularProgress';

import { ReactComponent as Logo } from './assets/FF1310.svg';
import Grid from "@mui/material/Grid2";

const LoadingLogin = () => {
  return (
    <Box>
      <CssBaseline />
      <AppBar position="static" color="primary">
        <Toolbar>
          <Logo height="50px" style={{marginTop: 7.5}}/>
        </Toolbar>
      </AppBar>
      <Grid
          container
          direction="row"
          style={{marginTop: 50}}
          sx={{
              justifyContent: "center",
              alignItems: "center",
          }}>
        <Grid size={{xs: 1}}>
            <CircularProgress />
        </Grid>
      </Grid>
    </Box>
    )
}

export default LoadingLogin;

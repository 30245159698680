// compat packages are API compatible with namespaced code
import firebase from "firebase/compat/app"
import "firebase/compat/remote-config";
import "firebase/compat/database";
import "firebase/compat/analytics";
import "firebase/compat/auth";




// Initialize Firebase

const Firebase = firebase.initializeApp({
  "projectId": "ff-1310",
  "appId": "1:258678627393:web:7eacee18d5440707043ac0",
  "databaseURL": "https://ff-1310.firebaseio.com",
  "storageBucket": "ff-1310.appspot.com",
  "locationId": "europe-west",
  "apiKey": "AIzaSyABXV6BCHmzwJGk5sGtQwlf0Sv60APAYlc",
  "authDomain": "ff-1310.firebaseapp.com",
  "messagingSenderId": "258678627393",
  "measurementId": "G-LB37N4MRYD"
});

const analytics = Firebase.analytics();
const database = Firebase.database();

export { Firebase , database }



